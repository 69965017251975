@import '../styles/mixins.scss';

$font-color: #444c67;

.welcome :global {
  text-align: center;

  .logo {
    width: 30vw;
    max-width: 301px;
    min-width: 150px;
  }

  h1 {
    font-size: 9vw;
    font-weight: 900;
  }

  h2 {
    font-size: 4vw;
    @include media-breakpoint-up(md) {
      font-size: 2vw;
    }
  }

  h3 {
    font-size: 3vw;
    @include media-breakpoint-up(md) {
      font-size: 1.5vw;
    }
  }

  h1,
  h2,
  h3 {
    color: $font-color;
  }

  .btn-rounded {
    font-size: 0.85rem;
    border-radius: 60px;
    padding: 3px 18px;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
      padding: 7px 18px;
    }
  }

  .brand-text {
    font-size: 0.85rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
}
