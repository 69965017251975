@import '../../styles/variables';

.project-content :global {
  .title {
    h4,
    h6 {
      font-weight: 500;
    }
  }

  code {
    color: $font-light-color;
    font-size: 0.857rem;
  }

  a.skip {
    font-size: 1.286rem;
    color: $info;
  }

  .file-info {
    font-size: 1.125rem;

    a {
      color: $body-color;
    }

    &.new-file {
      a {
        color: $info;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  .card-subtitle {
    color: $text-gray-color;
    font-weight: $font-weight-light;
    font-family: $secondary-font;
  }
}
