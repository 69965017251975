@import '../../styles/variables';

.tabs-nav :global {
  .nav-link {
    cursor: pointer;
    border: none;
    font-family: $secondary-font;
    margin-left: 0.25rem;
    background: transparent;
    transition: all 0.2s ease-in-out;

    &.active,
    &:hover {
      background: $info !important;
      color: white !important;
    }

    &.disabled {
      cursor: initial;
    }
  }
}
