@import '../styles/variables';

.partner-select :global {
  background: #ffb64d;
  padding: 1rem;
  margin-bottom: 2rem;

  select {
    height: auto;
  }
}
