.dashboard-widget-card :global {
  padding: 1.45rem;

  .dashboard-widget {
    display: flex;
    justify-content: space-between;
    color: white;

    .icon {
      font-size: 2.75rem;
      display: flex;
    }

    p,
    h2 {
      color: white;
    }

    p {
      font-size: 1rem;
    }
  }
}
