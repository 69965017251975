@import '../styles/variables';

.release-codes :global {
  .delivery-format {
    input[type='radio'] {
      margin-top: 0.5rem;
    }

    label {
      span {
        font-size: 1.3rem;
        color: rgb(90, 202, 247);
      }
    }
  }

  h5 {
    font-size: 1.15rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.29rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 1rem;
    font-weight: 200;
    line-height: 1.58rem;
  }

  .text-primary {
    color: #007bff !important;

    &.btn-link:hover {
      color: #0056b3 !important;
    }
  }

  .project-codes {
    h4 {
      font-weight: 500;
    }
  }

  .buy-more {
    font-weight: $font-weight-bold;
    color: $danger;
    text-decoration: underline;
  }
}
