.container :global {
  background-color: #6ca5c7;
  background-image: url('../assets/images/overlay.png'), linear-gradient(135deg, #aecae2 30%, #6ca5c7 70%);
  height: 100vh;
  overflow: auto;

  .wrapper {
    width: 64em;
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    font-size: 17pt;
    font-weight: 300;
    line-height: 1.65;
    margin: 0 auto;

    @media screen and (max-width: 1680px) {
      font-size: 14pt;
      max-width: calc(100% - 2em);
    }

    @media screen and (max-width: 1280px) {
      font-size: 12pt;
    }

    @media screen and (max-width: 360px) {
      font-size: 11pt;
    }
  }

  p {
    word-break: break-word;
  }

  .row {
    display: flex;
    flex-direction: column;
  }

  /* Button */
  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button,
  .button {
    appearance: none;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    border-radius: 8px;
    border: 0;
    cursor: pointer;
    display: inline-block;
    font-weight: 300;
    height: 2.75em;
    line-height: 2.75em;
    min-width: 9.25em;
    padding: 0 1.5em;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
  }

  input[type='submit'].icon,
  input[type='reset'].icon,
  input[type='button'].icon,
  button.icon,
  .button.icon {
    padding-left: 1.35em;
  }

  input[type='submit'].icon:before,
  input[type='reset'].icon:before,
  input[type='button'].icon:before,
  button.icon:before,
  .button.icon:before {
    margin-right: 0.5em;
  }

  input[type='submit'].fit,
  input[type='reset'].fit,
  input[type='button'].fit,
  button.fit,
  .button.fit {
    display: block;
    margin: 0 0 1em 0;
    width: 100%;
  }

  input[type='submit'].small,
  input[type='reset'].small,
  input[type='button'].small,
  button.small,
  .button.small {
    font-size: 0.8em;
  }

  input[type='submit'].big,
  input[type='reset'].big,
  input[type='button'].big,
  button.big,
  .button.big {
    font-size: 1.35em;
  }

  input[type='submit'].disabled,
  input[type='submit']:disabled,
  input[type='reset'].disabled,
  input[type='reset']:disabled,
  input[type='button'].disabled,
  input[type='button']:disabled,
  button.disabled,
  button:disabled,
  .button.disabled,
  .button:disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  @media screen and (max-width: 736px) {
    input[type='submit'],
    input[type='reset'],
    input[type='button'],
    button,
    .button {
      min-width: 0;
    }
  }

  input[type='submit'],
  input[type='reset'],
  input[type='button'],
  button,
  .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.35);
    color: #ffffff !important;
  }

  input[type='submit']:hover,
  input[type='reset']:hover,
  input[type='button']:hover,
  button:hover,
  .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }

  input[type='submit']:active,
  input[type='reset']:active,
  input[type='button']:active,
  button:active,
  .button:active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  input[type='submit'].icon:before,
  input[type='reset'].icon:before,
  input[type='button'].icon:before,
  button.icon:before,
  .button.icon:before {
    color: rgba(255, 255, 255, 0.5);
  }

  input[type='submit'].special,
  input[type='reset'].special,
  input[type='button'].special,
  button.special,
  .button.special {
    background-color: #8cc9f0;
    color: #ffffff !important;
    box-shadow: none;
  }

  input[type='submit'].special:hover,
  input[type='reset'].special:hover,
  input[type='button'].special:hover,
  button.special:hover,
  .button.special:hover {
    background-color: #9acff2;
  }

  input[type='submit'].special:active,
  input[type='reset'].special:active,
  input[type='button'].special:active,
  button.special:active,
  .button.special:active {
    background-color: #7ec3ee;
  }

  /* Form */

  label {
    display: block;
    font-size: 0.9em;
    font-weight: 400;
    margin: 0 0 1em 0;
  }

  input[type='text'],
  input[type='date'],
  input[type='password'],
  input[type='email'],
  select,
  textarea {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 8px;
    border: solid 1px;
    color: inherit;
    display: block;
    outline: 0;
    padding: 0 1em;
    text-decoration: none;
    width: 100%;
  }

  input[type='text']:invalid,
  input[type='date']:invalid,
  input[type='password']:invalid,
  input[type='email']:invalid,
  select:invalid,
  textarea:invalid {
    box-shadow: none;
  }
  input[type='text'],
  input[type='date'],
  input[type='password'],
  input[type='email'],
  select {
    height: 2.75em;
  }

  textarea {
    padding: 0.75em 1em;
  }

  ::-webkit-input-placeholder {
    opacity: 1;
  }

  :-moz-placeholder {
    opacity: 1;
  }

  ::-moz-placeholder {
    opacity: 1;
  }

  :-ms-input-placeholder {
    opacity: 1;
  }

  label {
    color: #ffffff;
  }

  input[type='text'],
  input[type='date'],
  input[type='password'],
  input[type='email'],
  select,
  textarea {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.35);
  }

  input[type='text']:focus,
  input[type='date']:focus,
  input[type='password']:focus,
  input[type='email']:focus,
  select:focus,
  textarea:focus {
    border-color: #8cc9f0;
    box-shadow: 0 0 0 1px #8cc9f0;
  }

  ::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  :-moz-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  ::-moz-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  :-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  .formerize-placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }

  /* List */

  ol {
    list-style: decimal;
    margin: 0 0 2em 0;
    padding-left: 1.25em;
  }

  ol li {
    padding-left: 0.25em;
  }

  ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
  }

  ul li {
    padding-left: 0.5em;
  }

  ul.actions {
    cursor: default;
    list-style: none;
    padding-left: 0;
  }

  ul.actions li {
    display: inline-block;
    padding: 0 1em 0 0;
    vertical-align: middle;
  }

  ul.actions li:last-child {
    padding-right: 0;
  }

  ul.actions.small li {
    padding: 0 0.5em 0 0;
  }

  ul.actions.vertical li {
    display: block;
    padding: 1em 0 0 0;
  }

  ul.actions.vertical li:first-child {
    padding-top: 0;
  }

  ul.actions.vertical li > * {
    margin-bottom: 0;
  }

  ul.actions.vertical.small li {
    padding: 0.5em 0 0 0;
  }

  ul.actions.vertical.small li:first-child {
    padding-top: 0;
  }

  ul.actions.fit {
    display: table;
    margin-left: -1em;
    padding: 0;
    table-layout: fixed;
    width: calc(100% + 1em);
  }

  ul.actions.fit li {
    display: table-cell;
    padding: 0 0 0 1em;
  }

  ul.actions.fit li > * {
    margin-bottom: 0;
  }

  ul.actions.fit.small {
    margin-left: -0.5em;
    width: calc(100% + 0.5em);
  }

  ul.actions.fit.small li {
    padding: 0 0 0 0.5em;
  }

  @media screen and (max-width: 480px) {
    ul.actions {
      margin: 0 0 2em 0;
    }

    ul.actions li {
      padding: 1em 0 0 0;
      display: block;
      text-align: center;
      width: 100%;
    }

    ul.actions li:first-child {
      padding-top: 0;
    }

    ul.actions li > * {
      width: 100%;
      margin: 0 !important;
    }

    ul.actions.small li {
      padding: 0.5em 0 0 0;
    }

    ul.actions.small li:first-child {
      padding-top: 0;
    }
  }

  dl {
    margin: 0 0 2em 0;
  }

  dl dt {
    display: block;
    font-weight: 400;
    margin: 0 0 1em 0;
  }

  dl dd {
    margin-left: 2em;
  }

  dl.alt dt {
    display: block;
    width: 3em;
    margin: 0;
    clear: left;
    float: left;
  }

  dl.alt dd {
    margin: 0 0 0.85em 5.5em;
  }

  dl.alt:after {
    content: '';
    display: block;
    clear: both;
  }

  ul.alt li {
    border-top-color: rgba(255, 255, 255, 0.35);
  }

  dl dt {
    color: #ffffff;
  }

  /* Section/Article */

  section.special,
  article.special {
    text-align: center;
  }

  header.major {
    margin-bottom: 3em;
  }

  header.major h2 {
    font-size: 2em;
  }

  header.major h2:after {
    display: block;
    content: '';
    width: 3.25em;
    height: 2px;
    margin: 0.7em 0 1em 0;
    border-radius: 2px;
  }

  section.special header.major h2:after,
  article.special header.major h2:after {
    margin-left: auto;
    margin-right: auto;
  }

  header.major p {
    font-size: 1.25em;
    letter-spacing: -0.025em;
  }

  header.major.special {
    text-align: center;
  }

  header.major.special h2:after {
    margin-left: auto;
    margin-right: auto;
  }

  @media screen and (max-width: 736px) {
    header.major {
      margin-bottom: 0;
    }

    header.major h2 {
      font-size: 1.5em;
    }

    header.major p {
      font-size: 1em;
      letter-spacing: 0;
    }

    header.major p br {
      display: none;
    }
  }

  header.major h2:after {
    background-color: rgba(255, 255, 255, 0.35);
  }

  /* Table */

  table {
    margin: 0 0 2em 0;
    width: 100%;
  }

  table tbody tr {
    border: solid 1px;
    border-left: 0;
    border-right: 0;
  }

  table td {
    padding: 0.75em 0.75em;
  }

  table th {
    font-size: 0.9em;
    font-weight: 400;
    padding: 0 0.75em 0.75em 0.75em;
    text-align: left;
  }

  table thead {
    border-bottom: solid 2px;
  }

  table tfoot {
    border-top: solid 2px;
  }

  table.alt {
    border-collapse: separate;
  }

  table.alt tbody tr td {
    border: solid 1px;
    border-left-width: 0;
    border-top-width: 0;
  }

  table.alt tbody tr td:first-child {
    border-left-width: 1px;
  }

  table.alt tbody tr:first-child td {
    border-top-width: 1px;
  }

  table.alt thead {
    border-bottom: 0;
  }

  table.alt tfoot {
    border-top: 0;
  }

  table tbody tr {
    border-color: rgba(255, 255, 255, 0.35);
  }

  table tbody tr:nth-child(2n + 1) {
    background-color: rgba(255, 255, 255, 0.075);
  }

  table th {
    color: #ffffff;
  }

  table thead {
    border-bottom-color: rgba(255, 255, 255, 0.35);
  }

  table tfoot {
    border-top-color: rgba(255, 255, 255, 0.35);
  }

  table.alt tbody tr td {
    border-color: rgba(255, 255, 255, 0.35);
  }

  #header {
    padding: 1em 5em 0.5em 5em;
    text-align: center;
  }

  #header h1 {
    margin: 0 0 0.25em 0;
  }

  #header p {
    font-size: 1.25em;
    letter-spacing: -0.025em;
  }

  #header.alt {
    padding: 7em 5em 4em 5em;
  }

  #header.alt h1 {
    font-size: 3.25em;
  }

  #header.alt > * {
    -moz-transition: opacity 3s ease;
    -webkit-transition: opacity 3s ease;
    -ms-transition: opacity 3s ease;
    transition: opacity 3s ease;
    -moz-transition-delay: 0.5s;
    -webkit-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    transition-delay: 0.5s;
    opacity: 1;
  }

  #header.alt .logo {
    -moz-transition: opacity 1.25s ease, -moz-transform 0.5s ease;
    -webkit-transition: opacity 1.25s ease, -webkit-transform 0.5s ease;
    -ms-transition: opacity 1.25s ease, -ms-transform 0.5s ease;
    transition: opacity 1.25s ease, transform 0.5s ease;
    -moz-transition-delay: 0s;
    -webkit-transition-delay: 0s;
    -ms-transition-delay: 0s;
    transition-delay: 0s;
    display: block;
    margin: 0 0 1.5em 0;
  }

  #header.alt .logo img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  @media screen and (max-width: 1280px) {
    #header {
      padding: 4em 4em 0.1em 4em;
    }

    #header.alt {
      padding: 6em 4em 3em 4em;
    }
  }

  @media screen and (max-width: 980px) {
    #header {
      padding: 4em 3em 0.1em 3em;
    }

    #header.alt {
      padding: 5em 3em 2em 3em;
    }
  }

  @media screen and (max-width: 736px) {
    #header {
      padding: 3em 2em 0.1em 2em;
    }

    #header p {
      font-size: 1em;
      letter-spacing: 0;
    }

    #header p br {
      display: none;
    }

    #header.alt {
      padding: 4em 2em 1em 2em;
    }

    #header.alt h1 {
      font-size: 2.5em;
    }
  }

  @media screen and (max-width: 480px) {
    #header {
      padding: 3em 1.5em 0.1em 1.5em;
    }

    #header.alt {
      padding: 4em 1.5em 1em 1.5em;
    }
  }

  @media screen and (max-width: 360px) {
    #header {
      padding: 2.5em 1em 0.1em 1em;
    }

    #header.alt {
      padding: 3.5em 1em 0.5em 1em;
    }
  }

  /* Main */
  #main {
    background-color: #ffffff;
    color: #636363;
    border-radius: 0.25em;
  }

  #main input,
  #main select,
  #main textarea {
    color: #636363;
  }

  #main a:hover {
    color: #636363;
  }

  #main h1,
  #main h2,
  #main h3,
  #main h4,
  #main h5,
  #main h6 {
    color: #636363;
  }

  #main input[type='submit'],
  #main input[type='reset'],
  #main input[type='button'],
  #main button,
  #main .button {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px #dddddd;
    color: #494949 !important;
  }

  #main input[type='submit']:hover,
  #main input[type='reset']:hover,
  #main input[type='button']:hover,
  #main button:hover,
  #main .button:hover {
    background-color: rgba(222, 222, 222, 0.25);
  }

  #main input[type='submit']:active,
  #main input[type='reset']:active,
  #main input[type='button']:active,
  #main button:active,
  #main .button:active {
    background-color: rgba(222, 222, 222, 0.5);
  }

  #main input[type='submit'].icon:before,
  #main input[type='reset'].icon:before,
  #main input[type='button'].icon:before,
  #main button.icon:before,
  #main .button.icon:before {
    color: rgba(99, 99, 99, 0.25);
  }

  #main input[type='submit'].special,
  #main input[type='reset'].special,
  #main input[type='button'].special,
  #main button.special,
  #main .button.special {
    background-color: #00aeef;
    color: #ffffff !important;
    box-shadow: none;
  }

  #main input[type='submit'].special:hover,
  #main input[type='reset'].special:hover,
  #main input[type='button'].special:hover,
  #main button.special:hover,
  #main .button.special:hover {
    background-color: #00aeef;
  }

  #main input[type='submit'].special:active,
  #main input[type='reset'].special:active,
  #main input[type='button'].special:active,
  #main button.special:active,
  #main .button.special:active {
    background-color: #00aeef;
  }

  #main input[type='submit'].special.icon:before,
  #main input[type='reset'].special.icon:before,
  #main input[type='button'].special.icon:before,
  #main button.special.icon:before,
  #main .button.special.icon:before {
    color: #ffffff !important;
  }

  #main label {
    color: #636363;
  }

  #main input[type='text'],
  #main input[type='password'],
  #main input[type='email'],
  #main select,
  #main textarea {
    background: rgba(222, 222, 222, 0.25);
    border-color: #dddddd;
  }

  #main input[type='text']:focus,
  #main input[type='password']:focus,
  #main input[type='email']:focus,
  #main select:focus,
  #main textarea:focus {
    border-color: #8cc9f0;
    box-shadow: 0 0 0 1px #8cc9f0;
  }

  #main input[type='checkbox'] + label,
  #main input[type='radio'] + label {
    color: #636363;
  }

  #main input[type='checkbox'] + label:before,
  #main input[type='radio'] + label:before {
    background: rgba(222, 222, 222, 0.25);
    border-color: #dddddd;
  }

  #main input[type='checkbox']:checked + label:before,
  #main input[type='radio']:checked + label:before {
    background-color: #636363;
    border-color: #636363;
    color: #ffffff;
  }

  #main input[type='checkbox']:focus + label:before,
  #main input[type='radio']:focus + label:before {
    border-color: #8cc9f0;
    box-shadow: 0 0 0 1px #8cc9f0;
  }

  #main ::-webkit-input-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
  }

  #main :-moz-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
  }

  #main ::-moz-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
  }

  #main :-ms-input-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
  }

  #main .formerize-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
  }

  #main .icon.major {
    border-color: #dddddd;
  }

  #main .icon.major:before {
    border-color: #dddddd;
  }

  #main .icon.alt {
    border-color: #dddddd;
    color: #636363;
  }

  #main .icon.alt:hover {
    background-color: rgba(222, 222, 222, 0.25);
  }

  #main .icon.alt:active {
    background-color: rgba(222, 222, 222, 0.5);
  }

  #main ul.alt li {
    border-top-color: #dddddd;
  }

  #main dl dt {
    color: #636363;
  }

  #main header.major h2:after {
    background-color: #00aeef;
    background-image: -moz-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -webkit-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -ms-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
  }

  #main table tbody tr {
    border-color: #dddddd;
  }

  #main table tbody tr:nth-child(2n + 1) {
    background-color: rgba(222, 222, 222, 0.25);
  }

  #main table th {
    color: #636363;
  }

  #main table thead {
    border-bottom-color: #dddddd;
  }

  #main table tfoot {
    border-top-color: #dddddd;
  }

  #main table.alt tbody tr td {
    border-color: #dddddd;
  }

  #main .spotlight .image {
    border-color: #dddddd;
  }

  #main > .main {
    padding: 5em 5em 3em 5em;
    border-top: solid 1px #dddddd;
  }

  #main > .main:first-child {
    border-top: 0;
  }

  #main > .main > .image.main:first-child {
    margin: -5em 0 5em -5em;
    width: calc(100% + 10em);
    border-top-right-radius: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  #main > .main > .image.main:first-child img {
    border-top-right-radius: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  @media screen and (max-width: 1280px) {
    #main > .main {
      padding: 4em 4em 2em 4em;
    }

    #main > .main > .image.main:first-child {
      margin: -4em 0 4em -4em;
      width: calc(100% + 8em);
    }
  }

  @media screen and (max-width: 980px) {
    #main > .main {
      padding: 4em 3em 2em 3em;
    }

    #main > .main > .image.main:first-child {
      margin: -4em 0 4em -3em;
      width: calc(100% + 6em);
    }
  }

  @media screen and (max-width: 736px) {
    #main > .main {
      padding: 3em 2em 1em 2em;
    }

    #main > .main > .image.main:first-child {
      margin: -3em 0 2em -2em;
      width: calc(100% + 4em);
    }
  }

  @media screen and (max-width: 480px) {
    #main > .main {
      padding: 3em 1.5em 1em 1.5em;
    }

    #main > .main > .image.main:first-child {
      margin: -3em 0 1.5em -1.5em;
      width: calc(100% + 3em);
    }
  }

  @media screen and (max-width: 360px) {
    #main {
      border-radius: 0;
    }

    #main > .main {
      padding: 2.5em 1em 0.5em 1em;
    }

    #main > .main > .image.main:first-child {
      margin: -2.5em 0 1.5em -1em;
      width: calc(100% + 2em);
      border-radius: 0;
    }

    #main > .main > .image.main:first-child img {
      border-radius: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300;
    line-height: 1.5;
    margin: 0 0 0.7em 0;
    letter-spacing: -0.025em;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
    text-decoration: none;
  }

  h1 {
    font-size: 2.5em;
    line-height: 1.2;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.25em;
  }

  h4 {
    font-size: 1.1em;
  }

  h5 {
    font-size: 0.9em;
  }

  h6 {
    font-size: 0.7em;
  }

  @media screen and (max-width: 736px) {
    h1 {
      font-size: 2em;
    }
  }
}
