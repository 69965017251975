@import '../styles/variables';

.header :global {
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 0 1rem;
  height: $header-height;

  .toggle-sidebar {
    cursor: pointer;
  }

  .logo {
    height: 28px;
  }

  .logo-text {
    margin-left: 1rem;
    font-size: 1rem;
  }

  .user-menu {
    .nav-item .nav-link {
      padding-top: 0;
      padding-bottom: 0;
    }

    .user-icon {
      font-size: 1.5rem;
    }
  }
}
