.qtty :global {
  display: inline-flex;
  border: 1px solid black;
  user-select: none;
  color: black;
  font-size: 1.3rem;

  .amount {
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .add, .remove {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    width: 35px;
    background: black;
    font-size: 1.5rem;
    color: white;
    cursor: pointer;
  }
}
