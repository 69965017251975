@import '../styles/variables';

.buy-codes :global {
  .dollar-sign {
    font-size: 2.5rem;
  }

  .balance {
    color: rgb(20, 145, 163);
  }

  .price-card {
    padding: 1.429rem;

    .small {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }

    .price {
      font-size: 1.714rem;
      font-weight: 700;
      display: inline-block;
    }

    .price-text {
      font-size: 0.714rem;
      font-weight: $font-weight-bold;
    }
  }
}
