@import '../styles/variables';

.sort-header :global {
  cursor: pointer;
  text-transform: none;
  text-decoration: none;

  .icons {
    svg {
      color: #cccccc;
      position: relative;

      &.fa-angle-up {
        top: 2px;
      }

      &.fa-angle-down {
        bottom: 2px;
      }

      &.active {
        color: $font-bold-color;
      }
    }
  }
}
