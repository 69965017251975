@import '../../styles/variables';

.collapsed {
  margin-left: 0rem;
  margin-right: 0rem;
}

.uncollapsed {
  margin-left: 0.5rem;
  margin-right: 4rem;
}

.nav-container {
  margin-top: 1rem;
  font-family: $secondary-font;

  :global {
    .nav-link {
      color: $sidebar-icon-color;
      &:hover {
        color: $sidebar-icon-color-hover;
      }

      &.active {
        color: $sidebar-icon-color-hover;
      }
    }

    .nav-link svg {
      width: 20px;
    }

    .nav-link span {
      margin-left: 1rem;
    }
  }
}
