@import '../../../styles/variables';

.search :global {
  margin-bottom: 1rem;

  button {
    color: $body-color;
    background: $gray-400;

    &:hover {
      color: $body-color;
    }
  }
}
