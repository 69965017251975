.projects :global {
  .table-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba($color: #000000, $alpha: 0.1);

    .spinner {
      margin-top: 4rem;
      width: 3rem;
      height: 3rem;
    }
  }
}
