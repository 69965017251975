@import './variables';
@import 'node_modules/bootstrap/scss/mixins/breakpoints';

@mixin login-form() {
  background: #ffffff;
  padding: 30px 30px 20px;
  border-radius: 2px;
  margin-bottom: 2rem;

  h4 {
    text-align: center;
    margin-bottom: 50px;
  }

  .btn.btn-info {
    width: 100%;
    text-transform: uppercase;
    font-size: 14px;
    padding: 15px;
    border: 0px;
  }

  .checkbox label {
    text-transform: none;
  }

  .checkbox {
    color: $font-bold-color;
  }

  label {
    color: $font-bold-color;
    text-transform: uppercase;
  }

  label a {
    color: #00bbe1;
  }

  .form-check {
    margin-top: 1.5rem;
  }
}
