@import 'variables';

.card {
  background: #ffffff none repeat scroll 0 0;
  margin: 1rem 0;
  padding: 1.875rem;
  border: 0 solid #e7e7e7;
  border-radius: 5px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}

.form-control {
  height: 42px;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    border-color: #e7e7e7;
  }

  &.is-invalid {
    &:hover {
      border-color: $danger;
    }
  }

  &.active,
  &:focus {
    box-shadow: none;
    border-color: #878787;
  }
}

.form-group {
  label {
    span.required {
      color: red;
    }
  }
}

.btn:hover:not(.disabled, .btn-link) {
  box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42),
    0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
}

.h-100vh {
  height: 100vh;
}

p {
  font-family: 'Poppins', sans-serif;
}

.page-wrapper {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  color: $font-bold-color;
}

.text-purple {
  color: $purple;
}

.text-blue {
  color: $blue;
}

a.text-blue:hover {
  color: darken($color: $blue, $amount: 15);
}
