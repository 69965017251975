@import url('https://fonts.googleapis.com/css?family=Open+Sans:400');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');
@import 'node_modules/react-redux-toastr/src/styles/index';

@import './styles/variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import './styles/template';

html {
  font-size: 14px;
}

body {
  margin: 0;
  padding: 0;
  font-family: $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn-welcome-new {
  @include button-variant(#5c4ac7, #5c4ac7);
  box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14),
    0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
  transition: 0.2s ease-in;

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42),
      0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
  }
}

.btn-welcome-sign-in {
  @include button-variant(#26dad2, #26dad2);
  color: white;
  box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14),
    0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
  transition: 0.2s ease-in;

  &:hover {
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42),
      0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
  }
}

a.disabled {
  opacity: 0.65;
  pointer-events: none;
  cursor: default;
}
