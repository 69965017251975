@import '../styles/variables';

.dashboard :global {
  .bg-card-1 {
    background: #1491a3;
  }
  .bg-card-2 {
    background: #e6a1f2;
  }
  .bg-card-3 {
    background: #26dad2;
  }
  .bg-card-4 {
    background: #fc6180;
  }
  .bg-card-5 {
    background: $warning;
  }
  .bg-card-6 {
    background: $info;
  }
  .bg-card-7 {
    background: #444b68;
  }

  .btn-buy {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  p {
    font-size: $font-small-size;
    color: $font-light-color;
    margin-bottom: 0;
    line-height: 1rem;
  }

  h3 {
    color: $font-bold-color;
  }
}
