@import '../../styles/mixins.scss';

.box :global {
  background: white;
  padding: 3rem;
  box-shadow: 0px 0px 9px -4px rgba(0,0,0,0.75);
  width: 100%;
  margin: 1rem;

  @include media-breakpoint-down(xs) {
    margin: 0;
    padding: 1.5rem;
  }

  @include media-breakpoint-up(md) {
    width: 450px;
  }

  h2 {
    margin-bottom: 3rem;
  }

  img.logo {
    height: 50px;
  }


  button {
    position: relative;
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    background-color: black;
    color: white;
    padding: 0.75rem 2rem;
    font-size: 1.25rem;
    width: 100%;
  }
}

.boxContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.20rem;
}
