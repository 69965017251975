@import 'node_modules/bootstrap/scss/functions';

// Bootstrap averrides
$primary: #5c4ac7;
$success: #56cd66;
$info: #01c6e9;
$input-border-color: #e7e7e7;
$input-color: #495057;

// GENERAL
$body-color: #67757c;

// LINKS
$link-color: $info;
$link-hover-decoration: none;
$link-hover-color: $link-color;

// CUSTOM
$content-bg: #fafafa;
$input-border-radius: 0;
$purple: #7460ee;

// FONTS
$body-font: 'Lato', sans-serif;
$secondary-font: 'Open Sans', sans-serif;

$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.25;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.3125;
$h4-font-size: $font-size-base * 1.125;
$h5-font-size: $font-size-base * 1;
$h6-font-size: $font-size-base * 0.875;
$font-small-size: 0.875rem;

$font-light-color: #607d8b;
$font-bold-color: #455a64;

// SIDEBAR
$sidebar-icon-color: #607d8b;
$sidebar-icon-color-hover: $info;

// HEADERS
$header-height: 60px;
$page-title-height: 60px;

// COLORS
$text-gray-color: #99abb4;

@import 'node_modules/bootstrap/scss/variables';
