@import '../styles/mixins.scss';

.container :global {
  .book {
    display: flex;
    align-items: center;
    justify-content: center;


    .book-info {
      padding-left: 1rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .qtty {
      align-self: center;
      margin-top: 2rem;
      margin-bottom: 1rem;
    }

    hr {
      border-color: black;
    }
  }
}
