@import '../styles/variables';

.page-title {
  background: white;
  color: $info;
  padding: 0rem 1rem;
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
  height: $page-title-height;
  display: flex;
  align-items: center;

  h3 {
    padding-left: 1rem;
    margin-bottom: 0;
    font-weight: 700;
  }
}
