@import '../../styles/variables';

.project-state :global {
  .badge {
    text-transform: uppercase;
    font-weight: $font-weight-normal;
  }

  .badge-success {
    color: white;
  }
}
