@import '../../styles/variables';

.project-row :global {
  td.download-links {
    a {
      font-size: $font-size-sm;
    }

    a:first-child {
      margin-top: 0 !important;
    }
  }

  td.edit-actions {
    a, .btn-link {
      transition: opacity 0.25s ease-in-out;
      opacity: 0;
    }

    &:hover a, &:hover .btn-link {
      opacity: 1;
    }
  }

  .state-select {
    select {
      height: auto;
    }
    button {
      padding: 1px 7px;
      font-size: $font-size-sm;
      cursor: pointer;

      &:disabled {
        cursor: initial;
      }
    }
  }
}
